import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';

function FormattedNumberInput({ value, onChange, name, maxLength = 16 }) {
  const [isEditing, setIsEditing] = useState(false);

  function formatNumber(value) {
    const number = parseFloat(value);
    if (!isNaN(number)) {
      return number.toLocaleString('en-GB');
    } else {
      return value;
    }
  }

  const handleInputChange = e => {
    const newValue = e.target.value.replace(/,/g, '');

    if (/^\d*\.?\d*$/.test(newValue) && newValue.length <= maxLength) {
      onChange({ target: { name, value: newValue } });
    }
  };

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  return (
    <Form.Control
      type="text"
      name={name}
      value={isEditing ? value : formatNumber(value)}
      onChange={handleInputChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
}

FormattedNumberInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  maxLength: PropTypes.number
};

export default FormattedNumberInput;
