import React, { useState, useEffect } from "react";
import { Button, Badge } from "react-bootstrap";
import { Form } from "react-bootstrap";
import PropTypes from 'prop-types';


const FormulaEditor = ({ formulaString, fields, saveFormula }) => {
  const [formula, setFormula] = useState([]);
  const [constant, setConstant] = useState("");
  const operators = ["+", "-", "*", "/", "(", ")"];

  useEffect(() => {
    console.log("Received formulaString:", formulaString);
  }, [formulaString]);

  useEffect(() => {
    if (formulaString) {
      const parsedFormula = formulaString.split(" ").map((idOrOperator) => {
        if (idOrOperator.startsWith("key")) {
          const id = parseInt(idOrOperator.replace("key", ""), 10);
          const field = fields.find((f) => f.id === id);
          return field ? field.name : idOrOperator;
        }
        return idOrOperator;
      });
      setFormula(parsedFormula);
    }
  }, [formulaString, fields]);

useEffect(() => {
  if (formula.length > 0) {
    const formulaWithNames = formula.map((item) => getFieldName(item));
    const formulaWithIds = formula.map((item) => getFieldId(item));
    saveFormula(formulaWithNames.join(" "), formulaWithIds.join(" "));
  }
}, [formula]);

  const getFieldId = (name) => {
    const field = fields.find((f) => f.name === name);
    return field ? `key${field.id}` : name;
  };

  const getFieldName = (id) => {
    const field = fields.find((f) => f.id === id);
    return field ? field.name : id;
  };

  const handleAddToFormula = (item) => {
    if (typeof item === "object" && item.id) {
      setFormula((prev) => [...prev, `key${item.id}`]);
    } else {
      setFormula((prev) => [...prev, item]);
    }
  };

  const handleRemoveFromFormula = (index) => {
    setFormula((prev) => prev.filter((_, i) => i !== index));
  };

  const handleClear = () => {
    setFormula([]);
  };

  const handleAddConstant = () => {
    if (constant.trim()) {
      setFormula((prev) => [...prev, constant]);
      setConstant("");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <div
        style={{
          border: "1px solid #ccc",
          padding: "10px",
          borderRadius: "5px",
          marginBottom: "20px",
          display: "flex",
          flexWrap: "wrap",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {formula.map((item, index) => (
            <Badge
              key={index}
              bg="primary"
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleRemoveFromFormula(index)}
            >
              {item}
            </Badge>
          ))}
        </div>
        <Button variant="danger" size="sm" onClick={handleClear}>
          Clear
        </Button>
      </div>

      {/* Operators */}
      <div style={{ marginBottom: "20px" }}>
        <strong>Operators:</strong>
        <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
          {operators.map((op) => (
            <Button
              key={op}
              variant="outline-secondary"
              onClick={() => handleAddToFormula(op)}
            >
              {op}
            </Button>
          ))}
        </div>
      </div>

      {/* Fields */}
      <div style={{ marginBottom: "20px" }}>
        <strong>Fields:</strong>
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            borderRadius: "5px",
            display: "flex",
            flexWrap: "wrap",
            gap: "5px",
          }}
        >
          {fields.map((field) => (
            <Badge
              key={field.id}
              bg="primary"
              style={{ cursor: "pointer" }}
              onClick={() => handleAddToFormula(field.name)}
            >
              {field.name}
            </Badge>
          ))}
        </div>
      </div>

      {/* Add Constant */}
      <div style={{ marginBottom: "20px" }}>
        <strong>Add Constant:</strong>
        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
          <Form.Control
            type="text"
            placeholder="Enter constant"
            value={constant}
            onChange={(e) => setConstant(e.target.value)}
            style={{ width: "200px" }}
          />
          <Button variant="success" onClick={handleAddConstant}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FormulaEditor;

FormulaEditor.propTypes = {
  formulaString: PropTypes.string,
  fields: PropTypes.array,
  saveFormula: PropTypes.func,
};

