import React, { useState, useEffect,  useRef } from 'react';
import { Form } from 'react-bootstrap';
import '../../../../../../assets/scss/user.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { upSert } from 'services/bs-sector-measurable/measurableDataService';
import { buildTargetTextDisplay } from 'helpers/kpiHelper';
import { inputPopulateType } from 'data/TempData';
import classNames from 'classnames';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

MesuarableTdItem.propTypes = {
  data: PropTypes.object,
  item: PropTypes.object,
  col: PropTypes.string,
  value: PropTypes.string,
  callback: PropTypes.func,
  noOfWeek: PropTypes.number,
  compareNoOfWeek: PropTypes.number
};


export function MesuarableTdItem({
  data,
  item,
  col,
  value,
  callback,
  noOfWeek,
  compareNoOfWeek
}) {
  const [idCellEditing, setIdCellEditing] = useState('');
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);
  const ManualEnterText = 'Manual Enter';
  const FormulaText = 'Formula';
  const [numberDisplay, setNumberDisplay] = useState('TBC');
  const [objDataColor, setObjDataColor] = useState({});
  const [classColor, setClassColor] = useState('');

  useEffect(() => {
    if (idCellEditing === `${col} ${item.id}` && inputRef.current) {
      inputRef.current.focus();
    }
  }, [idCellEditing, col, item.id]);

  useEffect(() => {
    let calNumber = value;
    if (
      inputPopulateType.find(x => x.value === item.inputMethod)?.label ===
      FormulaText
    ) {
      calNumber = applyFormula(data, item);
    }

    setNumberDisplay(calNumber);
    setObjDataColor({ date: new Date(col), value: calNumber });
  }, [data, item.inputMethod, col, value]);

  useEffect(() => {
    setClassColor(
      checkColor(
        item.variationPercentage,
        objDataColor,
        item.currentMonthTarget,
        item.previousMonthTarget
      )
    );
  }, [objDataColor]);

  const handleClick = () => {
    console.log(item, col);
    const inputMethod = inputPopulateType.find(
      x => x.value == item.inputMethod
    );
    if (inputMethod?.label == ManualEnterText) {
      setIdCellEditing(`${col} ${item.id}`);
    }
  };

  const handleOnBlur = () => {
    setIdCellEditing('');
    if (inputValue != value && inputValue != '') {
      upSert(item.id, col, inputValue).then(() => {
        callback();
      });
    }
  };
  const getValueFromDate = data => {
    return data.map(item => {
      const existed = item.dateData.find(x => x.date == `${col}T00:00:00`);
      return {
        id: item.id,
        value: existed?.value ?? 0,
        inputMethod: item.inputMethod,
        formula: item.formula
      };
    });
  };

  const checkColor = (
    variationPercentage,
    data,
    currentMonthTarget,
    previousMonthTarget
  ) => {
    if (!data || !data.date || !data.value || data.value == 0 || data.value == 'NaN') return '';

    let dateStr = dayjs(data?.date).format('YYYY-MM');
    let compareMonthTarget =
      dateStr == dayjs(currentMonthTarget?.date).format('YYYY-MM')
        ? currentMonthTarget
        : previousMonthTarget;

    if (variationPercentage == 0 || variationPercentage == undefined) {
      if (data.value == compareMonthTarget?.target) {
        return 'kpiWarning';
      } else if (parseInt(data.value) > parseInt(compareMonthTarget?.target)) {
        return 'kpiSafe';
      } else {
        return 'kpiDanger';
      }
    } else {
      let percent = (() => {
        const value =
          parseFloat((data?.value || '').replace(/,/g, ''), 10) || 0;
        const target =
          parseFloat(
            (compareMonthTarget?.target || '').replace(/,/g, ''),
            10
          ) || 0;

        if (target === 0) {
          return 0;
        }
        if (compareMonthTarget?.measurableId == 3) {
          console.log(value, target, data.value, compareMonthTarget?.target);
        }
        return (1 - value / target) * 100;
      })();

      if (percent > 0) {
        if (percent > variationPercentage) {
          if (compareMonthTarget?.measurableId == 3) {
            console.log(
              data?.value,
              compareMonthTarget?.target,
              percent,
              variationPercentage
            );
          }
          return 'kpiDanger';
        } else {
          return 'kpiWarning';
        }
      } else {
        return 'kpiSafe';
      }
    }
  };

  const applyFormula = (data, currentItem, visited = new Set()) => {
    const values = getValueFromDate(data);
    if (!values || values.length === 0) return; 

    const formula = JSON.parse(currentItem.formula)?.formulaWithIds;
    if (!formula) {
      return;
    }
    const formulaWithValues = formula
      .split(' ')
      .map(idOrOperator => {
        if (idOrOperator.startsWith('key')) {
          const id = parseInt(idOrOperator.replace('key', ''), 10);
          const valueObj = values.find(item => item.id === id); 
          if (visited.has(id)) {
            console.warn(`Detected circular reference for id: ${id}`);
            return idOrOperator; 
          }

          if (valueObj && valueObj.inputMethod === 3) {
            visited.add(id);
            const valueForFormula = applyFormula(
              data,
              valueObj,
              visited
            );
            visited.delete(id); 
            return valueForFormula !== undefined
              ? valueForFormula
              : idOrOperator; 
          }

          // Nếu không có inputMethod == 3, lấy giá trị từ valueObj
          return valueObj ? valueObj.value : idOrOperator;
        }
        return idOrOperator; // Giữ nguyên toán tử
      })
      .join(' ');

    const result = evaluateFormula(formulaWithValues);
    return result;
  };

  const evaluateFormula = formula => {
    try {
      const cleanedFormula = formula.replace(/,/g, '');
      return eval(cleanedFormula).toFixed(2);
    } catch (error) {
      console.error('Lỗi khi tính toán công thức:', error.message);
      return null;
    }
  };

  return (
    <td
      className={classNames('text-center p-1', classColor)}
      hidden={compareNoOfWeek < noOfWeek}
    >
      <div onClick={handleClick}>
        {`${col} ${item.id}` === idCellEditing ? (
          <Form.Control
            ref={inputRef}
            type="number"
            name="keyword"
            placeholder=""
            onChange={e => setInputValue(e.target.value)}
            value={inputValue}
            onBlur={handleOnBlur}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleOnBlur();
              }
            }}
          />
        ) : inputPopulateType.find(x => x.value === item.inputMethod)?.label ===
          FormulaText ? (
          buildTargetTextDisplay(item.targetTypeValue, numberDisplay) //
        ) : (
          buildTargetTextDisplay(item.targetTypeValue, value)
        )}
      </div>
    </td>
  );
}
