const toGBP = value => {
  return value.toLocaleString('en-UK', {
    style: 'currency',
    currency: 'GBP'
  });
};
export { toGBP, buildTargetTextDisplay, formatNumber };

const formatNumber = str => {
  const number = Number(str);
  if (!isNaN(number)) {
    return number.toLocaleString('en-GB');
  } else {
    return str;
  }
};

const buildTargetTextDisplay = (typeValue, data) => {
  if (!data) {
    if(!typeValue) {
      return 'TBC'
    }
    return typeValue;
  }

  let numericData = data;
  if (typeof data === 'string') {
    numericData = parseFloat(data.replace(/,/g, ''));
    if (isNaN(numericData)) {
      console.log("Invalid data format:", data);
      return ""; //
    }
  }

  const formattedData = new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(numericData);

  if (!typeValue) {
    return formattedData;
  }

  if (typeValue === '£') {
    return typeValue + ' ' + formattedData;
  }
  if (typeValue === '%') {
    return formattedData + ' ' + typeValue;
  }
  return formattedData + ' ' + typeValue;
};