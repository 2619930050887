import React from "react";
import { Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import "./FullPageSpinner.scss"; 

export default function FullPageSpinner({ show, quantity = 1, variant = "primary" }) {
  if (!show) return null;

  const spinners = [];
  for (let i = 0; i < quantity; i++) {
    spinners.push(
      <Spinner key={i} animation="grow" variant={variant} size="sm" />
    );
  }

  return (
    <div className="full-page-spinner">
      <div className="spinner-container">{spinners}</div>
    </div>
  );
}

FullPageSpinner.propTypes = {
  show: PropTypes.bool.isRequired,
  quantity: PropTypes.number,
  variant: PropTypes.string,
};
